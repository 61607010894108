// 个人业绩提成方案
import * as API from '@/api/index'

export default {
  //个人提成查询
  EmployeeCommissionSchemeAll: params => {
    return API.POST('api/employeeCommissionScheme/all', params)
  },
  //经手人查询 
  EmployeeCommissionSchemeAllHandler: params => {
    return API.POST('api/employeeCommissionScheme/allHandler', params)
  },
  //个人提成添加
  EmployeeCommissionSchemeCreate: params => {
    return API.POST('api/employeeCommissionScheme/create', params)
  },
   //个人提成详情-条件
   EmployeeCommissionSchemeCommission: params => {
    return API.POST('api/employeeCommissionScheme/commission', params)
  },
   //个人提成详情-门店
   EmployeeCommissionSchemeEntity: params => {
    return API.POST('api/employeeCommissionScheme/entity', params)
  },
   //个人提成详情-经手人
   EmployeeCommissionSchemeHandler: params => {
    return API.POST('api/employeeCommissionScheme/handler', params)
  },
   //个人提成修改
   EmployeeCommissionSchemeUpdate: params => {
    return API.POST('api/employeeCommissionScheme/update', params)
  },

}
