<template>
  <div class="content_body SalaryEmployeeCommissionScheme">
    <!-- 头部 -->
    <div class="nav_header">
      <el-row>
        <el-col :span="20">
          <el-form
            :inline="true"
            size="small"
            @submit.native.prevent
            @keyup.enter.native="handleSearch"
          >
            <el-form-item label="员工提成方案">
              <el-input
                v-model="searchData.Name"
                size="small"
                placeholder="输入员工提成方案搜索"
                clearable
                @clear="handleSearch"
              >
              </el-input>
            </el-form-item>
            <el-form-item label="业绩取值方案">
              <el-select
                v-model="searchData.PerformanceSchemeID"
                placeholder="请选择"
                filterable
                clearable
                size="small"
                @change="handleSearch"
              >
                <el-option
                  v-for="item in PerformanceSchemeValids"
                  :key="item.ID"
                  :label="item.Name"
                  :value="item.ID"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="有效性">
              <el-select
                v-model="searchData.Active"
                placeholder="选择有效性"
                clearable
                size="small"
                @change="handleSearch"
              >
                <el-option label="有效" :value="true"></el-option>
                <el-option label="无效" :value="false"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item>
              <el-button
                type="primary"
                size="small"
                @click="handleSearch"
                v-prevent-click
                >搜索</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
        <el-col :span="4" class="text_right">
          <el-button
            type="primary"
            size="small"
            @click="handleShow('add', 'dialogVisible')"
            v-prevent-click
            >新增
          </el-button>
        </el-col>
      </el-row>
    </div>
    <!-- 表格 -->
    <div class="martp_10">
      <el-table size="small" :data="tableData" v-loading="tableDataLoading">
        <el-table-column prop="Name" label="员工提成方案"></el-table-column>
        <el-table-column
          prop="PerformanceSchemeName"
          label="业绩取值方案"
        ></el-table-column>
        <el-table-column
          :formatter="(row) => (row.Calculation == 10 ? '取其一' : '叠加')"
          label="计算方式"
        ></el-table-column>
        <el-table-column
          :formatter="(row) => (row.Active ? '有效' : '无效')"
          label="有效性"
        >
        </el-table-column>
        <el-table-column label="操作" width="80px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="small"
              @click="handleShow('edit', 'dialogVisible', scope.row)"
              v-prevent-click
              >编辑</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 分页 -->
    <div class="pad_15 text_right">
      <el-pagination
        background
        v-if="paginations.total > 0"
        @current-change="handleCurrentChange"
        :current-page.sync="paginations.page"
        :page-size="paginations.page_size"
        :layout="paginations.layout"
        :total="paginations.total"
      ></el-pagination>
    </div>
    <!-- 新增编辑弹出层 -->
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="1100px"
      @close="handleClose(dialogVisibleType, 'dialogVisible', 'formData')"
    >
      <el-tabs v-model="activeName">
        <el-tab-pane label="方案设置" name="first">
          <el-scrollbar class="el-scrollbar_height">
            <el-form
              size="small"
              ref="formData"
              :model="formData"
              label-width="110px"
            >
              <el-form-item
                label="提成方案名称"
                prop="Name"
                :rules="[
                  {
                    required: true,
                    message: '请输入提成方案名称',
                    trigger: 'blur',
                  },
                ]"
              >
                <el-input
                  v-model="formData.Name"
                  placeholder="请输入提成方案名称"
                ></el-input>
              </el-form-item>
              <el-form-item
                label="业绩取值方案"
                prop="PerformanceSchemeID"
                :rules="[
                  {
                    required: true,
                    message: '请选择业绩取值方案',
                    trigger: 'change',
                  },
                ]"
              >
                <el-select
                  v-model="formData.PerformanceSchemeID"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="item in PerformanceSchemeValids"
                    :key="item.ID"
                    :label="item.Name"
                    :value="item.ID"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item v-if="dialogVisibleType == 'edit'" label="有效性">
                <el-radio-group v-model="formData.Active">
                  <el-radio :label="true">有效</el-radio>
                  <el-radio :label="false">无效</el-radio>
                </el-radio-group>
              </el-form-item>
              <el-form-item label="计算方式">
                <span slot="label">
                  计算方式
                  <el-popover
                    placement="top-start"
                    width="850px"
                    trigger="hover"
                  >
                    <p>计算方式说明：</p>
                    <p>
                      比如公司规定：业绩小于1万提3%，业绩超过1万提5%，业绩超过3万提8%，业绩5万以上提12%，则员工的业绩为4万，则计算方式如下：
                    </p>
                    <p>
                      （1）取其一（按照达到最高提点计算）：则员工4万的业绩对应的提点为8%，则提成为40000x8%=3200元
                    </p>
                    <p>
                      （2）叠加（只有超出标准部门按高标准计算）：则员工业绩分开提成,分别按业绩0~1万、1~3万、3~5万区间计算：
                    </p>
                    <p>
                      1万以下：10000x3%=300；1~3万：（30000-10000）x5%=1000；3~5万：（40000-30000）x8%=800，则最终提成为：300+1000+800=2100元
                    </p>
                    <el-button
                      type="text"
                      style="color: #dcdfe6"
                      icon="el-icon-info"
                      slot="reference"
                    ></el-button>
                  </el-popover>
                </span>
                <el-radio v-model="formData.Calculation" label="10"
                  >取其一</el-radio
                >
                <el-radio v-model="formData.Calculation" label="20"
                  >叠加</el-radio
                >
              </el-form-item>
              <el-form-item
                label="提成方案"
                prop="Commission"
                :rules="[{ required: true, message: '请设置提成方案' }]"
              >
                <el-button
                  type="primary"
                  size="small"
                  @click="handleShow('addCondition', 'innerVisible')"
                  >新增提成方案
                </el-button>
              </el-form-item>
            </el-form>
            <el-table
              size="small"
              :data="formData.Commission"
              style="width: calc(100% - 110px); margin-left: 110px"
            >
              <el-table-column prop="BeginPerformance" label="开始业绩(大于)">
                <template slot-scope="scope">{{
                  scope.row.BeginPerformance | NumFormat
                }}</template>
              </el-table-column>
              <el-table-column prop="EndPerformance" label="截止业绩(小于等于)">
                <template slot-scope="scope">{{
                  scope.row.EndPerformance | NumFormat
                }}</template>
              </el-table-column>
              <el-table-column prop="Rate" label="比例提成">
                <template slot-scope="scope">{{ scope.row.Rate }}%</template>
              </el-table-column>
              <el-table-column prop="Fixed" label="固定提成(元)">
                <template slot-scope="scope"
                  >￥{{ scope.row.Fixed | NumFormat }}</template
                >
              </el-table-column>
              <el-table-column prop="address" label="操作" width="145px">
                <template slot-scope="scope">
                  <el-button
                    type="primary"
                    size="small"
                    @click="handleShow('editCondition', 'innerVisible', scope)"
                  >
                    编辑</el-button
                  >
                  <el-button
                    type="danger"
                    size="small"
                    @click="onSelConditionBtn(scope.$index)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane label="适用经手人" name="second">
          <el-table style="width: 100%" class="headTabel">
            <el-table-column label="经手人类型" width="100px"></el-table-column>
            <el-table-column label="组织单位" width="120"></el-table-column>
            <el-table-column label="经手人" width="210"></el-table-column>
            <el-table-column label="经手人对应职务"></el-table-column>
          </el-table>
          <el-table
            size="small"
            :data="handlingTableData"
            max-height="450px"
            :show-header="false"
            :border="true"
          >
            <el-table-column prop="Name" width="100px"></el-table-column>
            <el-table-column
              prop="Child"
              style="padding: 0px"
              class-name="subMenuTable"
            >
              <template slot-scope="scope1">
                <el-table
                  size="small"
                  :data="scope1.row.Child"
                  :show-header="false"
                  empty-text=" "
                >
                  <el-table-column
                    prop="EntityName"
                    width="120px"
                  ></el-table-column>
                  <el-table-column class-name="subMenuTable">
                    <template slot-scope="scope2">
                      <el-table
                        size="small"
                        :data="scope2.row.Handler"
                        :show-header="false"
                        empty-text=" "
                      >
                        <el-table-column prop="Name" width="210px">
                        </el-table-column>
                        <el-table-column>
                          <template slot-scope="scope3">
                            <el-row>
                              <el-col
                                :span="8"
                                v-for="(p, index) in scope3.row.Job"
                                :key="index"
                                style="overflow: hidden"
                              >
                                <el-checkbox
                                  size="small"
                                  v-model="p.IsSelected"
                                  class="padlt_10"
                                  :label="p.JobName"
                                >
                                </el-checkbox>
                              </el-col>
                            </el-row>
                          </template>
                        </el-table-column>
                      </el-table>
                    </template>
                  </el-table-column>
                </el-table>
              </template>
            </el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="适用门店" name="third">
          <el-scrollbar class="el-scrollbar_height">
            <el-tree
              :data="handlingEntity"
              show-checkbox
              node-key="ID"
              ref="tree"
              :expand-on-click-node="false"
              :check-on-click-node="true"
              :check-strictly="true"
              :default-checked-keys="defaultCheckedKeys"
              :props="defaultProps"
              :default-expanded-keys="defaultExpandedKeys"
            >
              <span slot-scope="{ data }">
                <span class="font_14">{{ data.EntityName }}</span>
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="data.IsStore"
                  >门店</el-tag
                >
                <el-tag
                  class="marlt_5"
                  type="info"
                  size="mini"
                  v-if="data.IsWarehouse"
                  >仓库</el-tag
                >
              </span>
            </el-tree>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="dialogVisible = false">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave(dialogVisibleType)"
          :loading="saveLoading"
          >保 存</el-button
        >
      </span>
    </el-dialog>
    <!-- 设置条件 -->
    <el-dialog
      width="30%"
      :title="setCriteriaTitle"
      :visible.sync="innerVisible"
      @close="
        handleClose(setCriteriaType, 'innerVisible', 'setCriteriaFormData')
      "
    >
      <el-form
        size="small"
        ref="setCriteriaFormData"
        :model="setCriteriaFormData"
        label-width="110px"
      >
        <el-form-item label="条件">
          <span slot="label"
            ><span style="margin-right: 4px; color: #f67979">*</span
            ><span>业绩范围</span></span
          >
          <el-col :span="8">
            <el-form-item
              label-width="0"
              style="margin-bottom: 0px !important"
              prop="BeginPerformance"
              :rules="[{ required: true, message: '请输入开始业绩' }]"
            >
              <el-input
                v-model="setCriteriaFormData.BeginPerformance"
                type="number"
                @blur="setCriteriaAmount('BeginPerformance', $event)"
                placeholder="请输入开始业绩"
              >
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :span="2" class="dis_flex flex_x_center">至</el-col>
          <el-col :span="8">
            <el-form-item
              label-width="0"
              style="margin-bottom: 0px !important"
              prop="EndPerformance"
              :rules="[{ required: true, message: '请输入截止业绩' }]"
            >
              <el-input
                v-model="setCriteriaFormData.EndPerformance"
                type="number"
                @blur="setCriteriaAmount('EndPerformance', $event)"
                placeholder="请输入截止业绩"
              >
              </el-input>
            </el-form-item>
          </el-col>
        </el-form-item>
        <el-form-item
          label="比例提成"
          prop="Rate"
          :rules="[{ required: true, message: '请输入比例提成' }]"
        >
          <el-input
            v-model="setCriteriaFormData.Rate"
            @input="setCriteriaRate"
            v-enter-number3
            type="number"
          >
            <template slot="append">%</template>
          </el-input>
        </el-form-item>
        <el-form-item
          label="固定提成"
          prop="Fixed"
          :rules="[{ required: true, message: '请输入固定提成' }]"
        >
          <el-input
            v-model="setCriteriaFormData.Fixed"
            type="number"
            @blur="setCriteriaAmount('Fixed', $event)"
          >
            <template slot="append">元</template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="innerVisible = false">取 消</el-button>
        <el-button
          size="small"
          type="primary"
          @click="handleSave(setCriteriaType)"
          >保 存</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import API from "@/api/KHS/Salary/employeeCommissionScheme";
import performanceAPI from "@/api/KHS/Salary/performanceScheme";
import APIEntity from "@/api/KHS/Entity/entity";
export default {
  name: "SalaryEmployeeCommissionScheme",

  components: {},

  directives: {},

  data() {
    return {
      saveLoading: false,
      tableDataLoading: false,
      PerformanceSchemeValids: [], //业绩方案列表
      searchData: {
        Name: null,
        PerformanceSchemeID: null,
        Active: true,
      },
      tableData: [], //列表
      paginations: {
        page: 1, // 当前位于哪页
        total: 0, // 总数
        page_size: 10, // 1页显示多少条
        layout: "total, prev, pager, next,jumper", // 翻页属性
      },
      dialogTitle: "",
      dialogVisible: false,
      activeName: "first",
      // 新增编辑
      dialogVisibleType: "",
      formData: {
        Name: "",
        PerformanceSchemeID: "",
        Commission: [],
        Calculation: "10",
      },
      // 新增编辑设置条件
      setCriteriaFormData: {
        BeginPerformance: "",
        EndPerformance: "",
        Rate: "",
        Fixed: "",
      },
      setCriteriaType: "", //弹层类型
      setCriteriaTitle: "", //设置条件弹出层标题
      innerVisible: false, //是否显示条件弹出层
      setCriteriaEditID: "",
      setCriteriaEditIndex: "",
      // 经手人
      handlersList: [],
      handlingTableData: [],
      EmployeeCommissionSchemeHandlers: [], //经手人回显数据
      //经手人门店
      handlingEntity: [],
      defaultCheckedKeys: [],
      defaultExpandedKeys: [1],
      defaultProps: {
        children: "Child",
        label: "EntityName",
      },
    };
  },

  mounted() {
    const that = this;
    that.handleSearch();
    that.PerformanceSchemeValid();
    that.EmployeeCommissionSchemeAllHandler();
    that.entityData();
  },

  methods: {
    // 获取业绩方案
    PerformanceSchemeValid() {
      const that = this;
      performanceAPI.PerformanceSchemeValid().then((res) => {
        if (res.StateCode == 200) {
          that.PerformanceSchemeValids = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 获取经手人
    EmployeeCommissionSchemeAllHandler() {
      const that = this;
      API.EmployeeCommissionSchemeAllHandler().then((res) => {
        if (res.StateCode == 200) {
          let params;
          if (res.Data.project.length) {
            params = {
              Name: "销售-项目",
              Child: res.Data.project,
            };
            that.handlersList.push(params);
          }
          if (res.Data.product.length) {
            params = {
              Name: "销售-产品",
              Child: res.Data.product,
            };
            that.handlersList.push(params);
          }
          if (res.Data.generalCard.length) {
            params = {
              Name: "销售-通用次卡",
              Child: res.Data.generalCard,
            };
            that.handlersList.push(params);
          }
          if (res.Data.timeCard.length) {
            params = {
              Name: "销售-时效卡",
              Child: res.Data.timeCard,
            };
            that.handlersList.push(params);
          }
          if (res.Data.savingCard.length) {
            params = {
              Name: "销售-储值卡",
              Child: res.Data.savingCard,
            };
            that.handlersList.push(params);
          }
          if (res.Data.packageCard.length) {
            params = {
              Name: "销售-套餐卡",
              Child: res.Data.packageCard,
            };
            that.handlersList.push(params);
          }
          if (res.Data.treatProject.length) {
            params = {
              Name: "消耗-项目",
              Child: res.Data.treatProject,
            };
            that.handlersList.push(params);
          }
          if (res.Data.treatProduct.length) {
            params = {
              Name: "消耗-产品",
              Child: res.Data.treatProduct,
            };
            that.handlersList.push(params);
          }
          if (res.Data.treatSavingCard.length) {
            params = {
              Name: "消耗-储值卡",
              Child: res.Data.treatSavingCard,
            };
            that.handlersList.push(params);
          }
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 门店列表
    entityData: function () {
      var that = this;
      APIEntity.getEntityAll()
        .then((res) => {
          if (res.StateCode == 200) {
            that.handlingEntity = res.Data;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(function () {
          that.loading = false;
        });
    },
    // 详情-条件
    EmployeeCommissionSchemeCommission(ID) {
      const that = this;
      API.EmployeeCommissionSchemeCommission({ ID }).then((res) => {
        if (res.StateCode == 200) {
          that.formData.Commission = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 详情-经手人
    EmployeeCommissionSchemeHandler(ID) {
      const that = this;
      API.EmployeeCommissionSchemeHandler({ ID }).then((res) => {
        if (res.StateCode == 200) {
          that.EmployeeCommissionSchemeHandlers = res.Data;
          that.HandlerEcho();
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    //经手人回显
    HandlerEcho() {
      const that = this;
      that.handlingTableData.forEach((item) => {
        if (item.Child.length) {
          item.Child.forEach((item0) => {
            if (item0.Handler.length) {
              item0.Handler.forEach((item1) => {
                switch (item.Name) {
                  case "销售-项目":
                    that.EchoData(
                      that.EmployeeCommissionSchemeHandlers.project,
                      item1
                    );

                    break;
                  case "销售-产品":
                    that.EchoData(
                      that.EmployeeCommissionSchemeHandlers.product,
                      item1
                    );
                    break;
                  case "销售-通用次卡":
                    that.EchoData(
                      that.EmployeeCommissionSchemeHandlers.generalCard,
                      item1
                    );
                    break;
                  case "销售-时效卡":
                    that.EchoData(
                      that.EmployeeCommissionSchemeHandlers.timeCard,
                      item1
                    );
                    break;
                  case "销售-储值卡":
                    that.EchoData(
                      that.EmployeeCommissionSchemeHandlers.savingCard,
                      item1
                    );
                    break;
                  case "销售-套餐卡":
                    that.EchoData(
                      that.EmployeeCommissionSchemeHandlers.packageCard,
                      item1
                    );
                    break;
                  case "消耗-项目":
                    that.EchoData(
                      that.EmployeeCommissionSchemeHandlers.treatProject,
                      item1
                    );
                    break;
                  case "消耗-产品":
                    that.EchoData(
                      that.EmployeeCommissionSchemeHandlers.treatProduct,
                      item1
                    );
                    break;
                  case "消耗-储值卡":
                    that.EchoData(
                      that.EmployeeCommissionSchemeHandlers.treatSavingCard,
                      item1
                    );
                    break;
                }
              });
            }
          });
        }
      });

      // that.handlingTableData = handlingTableData;
    },
    EchoData(arr, item) {
      let num1;
      let num2;
      num1 = arr.findIndex((Handler) => Handler.GoodHandlerID == item.ID);
      if (num1 != -1) {
        if (item.Job.length) {
          item.Job.forEach((item1) => {
            num2 = arr[num1].JobTypeID.findIndex(
              (Handler) => Handler == item1.JobTypeID
            );
            if (num2 != -1) {
              item1.IsSelected = true;
            } else {
              item1.IsSelected = false;
            }
          });
        }
      }
    },
    // 详情-门店
    EmployeeCommissionSchemeEntity(ID) {
      const that = this;
      API.EmployeeCommissionSchemeEntity({ ID }).then((res) => {
        if (res.StateCode == 200) {
          that.defaultCheckedKeys = res.Data;
          that.defaultExpandedKeys = res.Data;
        } else {
          that.$message.error({
            message: res.Message,
            duration: 2000,
          });
        }
      });
    },
    // 提成比例
    setCriteriaRate(e) {
      if (Number(e) > 100) {
        this.setCriteriaFormData.Rate = 100;
      }
    },
    // 金额格式
    setCriteriaAmount(type, e) {
      if (e.target.value.indexOf(".") != -1) {
        this.setCriteriaFormData[type] = Number(e.target.value).toFixed(2) - 0;
      } else {
        this.setCriteriaFormData[type] = Number(e.target.value);
      }
    },

    // 搜索
    handleSearch() {
      this.paginations.page = 1;
      this.EmployeeCommissionSchemeAll();
    },
    // 获取列表
    EmployeeCommissionSchemeAll() {
      const that = this;
      const params = {
        Name: that.searchData.Name,
        PerformanceSchemeID: that.searchData.PerformanceSchemeID,
        Active: that.searchData.Active,
        PageNum: that.paginations.page,
      };
      that.tableDataLoading = true;
      API.EmployeeCommissionSchemeAll(params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.tableData = res.List;
            that.paginations.total = res.Total;
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.tableDataLoading = false;
        });
    },
    // 分页
    handleCurrentChange() {
      this.EmployeeCommissionSchemeAll();
    },

    handleSaveEdit() {
      const that = this;
      let param;
      // 条件设置
      const params = { ...that.formData };
      // 经手人
      params.ProductHandler = [];
      params.ProjectHandler = [];
      params.GeneralCardHandler = [];
      params.TimeCardHandler = [];
      params.SavingCardHandler = [];
      params.PackageCardHandler = [];
      params.TreatProductHandler = [];
      params.TreatProjectHandler = [];
      params.TreatSavingCardHandler = [];
      if (that.handlingTableData.length) {
        that.handlingTableData.forEach((item) => {
          if (item.Child.length) {
            item.Child.forEach((item0) => {
              if (item0.Handler.length) {
                item0.Handler.forEach((item1) => {
                  param = {
                    GoodHandlerID: item1.ID,
                    JobTypeID: [],
                  };
                  if (item1.Job.length) {
                    item1.Job.forEach((item2) => {
                      if (item2.IsSelected) {
                        param.JobTypeID.push(item2.JobTypeID);
                      }
                    });
                  }

                  if (param.JobTypeID.length) {
                    switch (item.Name) {
                      case "销售-项目":
                        params.ProjectHandler.push(param);
                        break;
                      case "销售-产品":
                        params.ProductHandler.push(param);
                        break;
                      case "销售-通用次卡":
                        params.GeneralCardHandler.push(param);
                        break;
                      case "销售-时效卡":
                        params.TimeCardHandler.push(param);
                        break;
                      case "销售-储值卡":
                        params.SavingCardHandler.push(param);
                        break;
                      case "销售-套餐卡":
                        params.PackageCardHandler.push(param);
                        break;
                      case "消耗-项目":
                        params.TreatProjectHandler.push(param);
                        break;
                      case "消耗-产品":
                        params.TreatProductHandler.push(param);
                        break;
                      case "消耗-储值卡":
                        params.TreatSavingCardHandler.push(param);
                        break;
                    }
                  }
                });
              }
            });
          }
        });
      }
      // 经手人门店
      params.Entity = that.$refs.tree.getCheckedKeys();
      let api;
      if (that.dialogVisibleType == "add") {
        api = "EmployeeCommissionSchemeCreate";
      } else {
        api = "EmployeeCommissionSchemeUpdate";
      }
      that.saveLoading = true;
      API[api](params)
        .then((res) => {
          if (res.StateCode == 200) {
            that.$message.success({
              message:
                that.dialogVisibleType == "add" ? "添加成功" : "修改成功",
              duration: 2000,
            });
            that.dialogVisible = false;
            that.handleSearch();
          } else {
            that.$message.error({
              message: res.Message,
              duration: 2000,
            });
          }
        })
        .finally(() => {
          that.saveLoading = false;
        });
    },
    // 员工业绩提成方案保存
    handleSaveBtn() {
      const that = this;
      that.$refs.formData.validate((valid) => {
        if (valid) {
          that.handleSaveEdit();
        }
      });
    },

    // 条件设置保存
    setCriteriaSaveBtn() {
      const that = this;
      this.$refs.setCriteriaFormData.validate((valid) => {
        if (valid) {
          // 结束数额不能小于开始数额
          if (
            that.setCriteriaFormData.BeginPerformance -
              that.setCriteriaFormData.EndPerformance >
            0
          ) {
            that.$message.error({
              message: "结束数额不能小于开始数额",
              duration: 2000,
            });
            return;
          }
          // 条件不能重复
          var num = that.formData.Commission.findIndex((item, index) => {
            if (
              that.setCriteriaType == "editCondition" &&
              index == that.setCriteriaEditIndex
            )
              return false;
            const num1 = Number(item.BeginPerformance);
            const num2 = Number(item.EndPerformance);
            const num3 = Number(that.setCriteriaFormData.BeginPerformance);
            const num4 = Number(that.setCriteriaFormData.EndPerformance);
            if (num1 <= num3 && num2 >= num4) return true;
            if (num1 >= num3 && num2 <= num4) return true;
            if (num1 >= num3 && num4 >= num1 && num2 >= num4) return true;
            if (num1 <= num3 && num3 <= num2 && num2 <= num4) return true;
          });
          if (num != -1) {
            that.$message.error({
              message: "条件设置存在重复数额",
              duration: 2000,
            });
            return;
          }
          switch (that.setCriteriaType) {
            case "addCondition":
              that.formData.Commission.push({ ...that.setCriteriaFormData });
              break;
            case "editCondition":
              var index = that.setCriteriaEditIndex;
              that.formData.Commission.splice(index, 1, {
                ...that.setCriteriaFormData,
              });
              break;
          }
          that.innerVisible = false;
        }
      });
    },
    // 删除
    onSelConditionBtn(index) {
      this.$confirm("此操作将删除该选项, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.formData.Commission.splice(index, 1);
          this.$message({
            type: "success",
            message: "删除成功!",
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 显示弹层
    async handleShow(type, bool, row) {
      const that = this;
      that[bool] = true;
      switch (type) {
        // 新增员工提成方案弹层
        case "add":
          that.handlingTableData = JSON.parse(
            JSON.stringify(that.handlersList)
          );
          that.dialogVisibleType = type;
          that.dialogTitle = "新增员工业绩提成方案";
          break;
        // 编辑员工提成方案弹层
        case "edit":
          that.handlingTableData = JSON.parse(
            JSON.stringify(that.handlersList)
          );
          that.dialogVisibleType = type;
          that.dialogTitle = "编辑员工业绩提成方案";
          that.EmployeeCommissionSchemeCommission(row.ID);
          that.EmployeeCommissionSchemeEntity(row.ID);
          that.EmployeeCommissionSchemeHandler(row.ID);
          await that.$nextTick();
          that.formData = {
            Name: row.Name,
            PerformanceSchemeID: row.PerformanceSchemeID,
            Commission: [],
            Calculation: row.Calculation,
            ID: row.ID,
          };
          that.$set(that.formData, "Active", row.Active);
          break;
        // 新增设置条件
        case "addCondition":
          that.setCriteriaTitle = "新增提成方案";
          that.setCriteriaType = type;
          break;
        // 编辑设置条件
        case "editCondition":
          that.setCriteriaTitle = "编辑提成方案";
          that.setCriteriaType = type;
          var scope = row;
          that.setCriteriaEditIndex = scope.$index;
          row = scope.row;
          await that.$nextTick();
          that.setCriteriaFormData = {
            BeginPerformance: row.BeginPerformance,
            EndPerformance: row.EndPerformance,
            Rate: row.Rate,
            Fixed: row.Fixed,
          };
          break;
      }
    },
    // 保存弹层
    handleSave(type) {
      const that = this;
      switch (type) {
        case "add":
        case "edit":
          that.handleSaveBtn();
          break;
        case "addCondition":
        case "editCondition":
          that.setCriteriaSaveBtn();
          break;
      }
    },
    // 关闭弹层
    handleClose(type, bool, ref) {
      const that = this;
      let isRef = false; //控制是否执行Ref清空表单
      that[bool] = false; //关闭弹层

      switch (type) {
        // 关闭员工提成方案
        case "add":
        case "edit":
          that.activeName = "first";
          that.handlingTableData = [];
          that.$refs.tree.setCheckedKeys([]);
          break;
      }
      // 重置表单
      if (isRef) return;
      that.$refs[ref].resetFields();
    },
  },
};
</script>

<style lang="scss" >
.SalaryEmployeeCommissionScheme {
  .el-scrollbar_height {
    height: 55vh;
    .el-scrollbar__wrap {
      overflow-x: hidden;
    }
  }
  .subMenuTable {
    padding: 0px !important;
    .cell {
      padding: 0px;
    }
  }
  .headTabel {
    .el-table__body-wrapper.is-scrolling-none {
      display: none;
    }
    .has-gutter > tr > th {
      padding: 8px 0 !important;
    }
  }
  .el-input__inner {
    padding: 0 0 0 15px;
  }
}
</style>